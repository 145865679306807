body {
    background-color: #f8f4e5 !important;
}

.spinner {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
}

.spinner img {
    display: block;
    margin: 10% auto;
}

.landing {
    background: white url("assets/img/contact-landing.jpg") no-repeat scroll center;
    background-size: cover;
    height: 100vh;
    color: white;
}

.wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
}